// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:ab8c85c1-e206-4759-b308-aa954b02fec8",
    "aws_cognito_region": "ap-northeast-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "e499f97d5bd34daa9af16f443de6251f",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
